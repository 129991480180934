const sizeChart4i = function(){
	const tabs = new tabs4i();
	let sizingChartDialog, 
	brandTagId = brandId,
	productReportSku = reportSku,
	productID = prodID,
	productCatalogAlias = catalogAlias;
	
	let init = () => {
		sizingChartDialog = new Dialog4i();
		
		const loadingIcon = '<div class="spinner" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading size charts, please wait"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
		sizingChartDialog.Init({
			triggerElements: [].slice.call(document.querySelectorAll(".sizeChartLink")),
			dialogClass: "dialogSizeCharts largeDialog",
			title: document.getElementById('gtmBrand').value + " Sizing Details",
			content: loadingIcon,
			onOpen: open
		});
	},
	open = el => {
		fetchSizeCharts();
	},
	fetchSizeCharts = async () => {
		const data = {
			BrandTagId: brandTagId,
			ReportSku: productReportSku,
			ProductId: productID,
			CatalogAlias : productCatalogAlias
		};

		let responseJSON;
		try{
			const response = await fetch('/productdata/sizecharts', {
				method: 'POST', 
				body: JSON.stringify(data),
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			});
			if(!response.ok) throw new Error(`Error processing size chart request: ${response.statusText}`);
			responseJSON = await response.json();
		}catch(error){
			console.error(error);
			// error function here
		}
		
		if(responseJSON){
			if (responseJSON.success == true) updateContent(responseJSON);
			//else //error function here
		}
	},
	updateContent = (response) => {
		const dialogContent = document.getElementById("popUpDialog").querySelector(".dialogContent");
		dialogContent.innerHTML = response.partialView;

		let threesixty = document.createElement('p');
		threesixty.innerHTML = response.threesixtyblurb;
		dialogContent.appendChild(threesixty);

		tabs.init(document.getElementById("sizingTabs"));
		document.getElementById("fitratings").addEventListener("click", showFitRate);
	},
	showFitRate = (e) => {
		e.preventDefault();
		sizingChartDialog.Close();
		let tab = document.getElementById('fitRatingTS');
		if (tab) {
			tab.click();
			tab.focus();
			setTimeout(() => {
				tab.scrollIntoView({ behavior: "smooth" });
			}, 200); 
		} else {
			setTimeout(() => {
				waitForTabsToAppear("fitRatingTS"); 
			}, 1200);
		}
	},
	waitForTabsToAppear = (activatedTabId) => {
		document.querySelector("#detailTabs").scrollIntoView();
		
		//allow the tabs to 'appear'
		const callback = function(mutationsList, observer) {
			for (let i = 0; i < mutationsList.length; i++) {
 				if (mutationsList[i].type === 'childList') {
					let tab = document.getElementById(activatedTabId);
 					tab.click();
 					tab.focus();
 					tabObserver.disconnect();
				}
			}
		};
		const tabObserver = new MutationObserver(callback);
		tabObserver.observe(document.getElementById('detailTabs'), { 
			childList: true, 
			subtree: true 
		});
	};

	return{
		Init: init
	};
};